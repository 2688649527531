import { Component, Input } from '@angular/core';
import { Menu } from '../../models/menu.model';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrl: './side-bar.component.scss'
})
export class SideBarComponent {

  @Input() menuData: Array<Menu> = [];

  public isCollapsed = true;

}
