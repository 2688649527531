<div class="col-12 mb-3">
  @if (!menuItem!.children) {
  <a
    [routerLink]="menuItem?.route"
    (click)="slideMenuService.showed = false"
    class="btn d-flex align-items-center justify-content-between"
  >
    <span class="d-flex align-items-center">
      @if (menuItem!.icon) {
        <i class="material-icons me-2">
          {{ menuItem!.icon }}
        </i>
      }
      {{ menuItem!.title }}
    </span>
  </a>
  } @if (menuItem!.children) {
    <button
      (click)="isCollapsed = !isCollapsed"
      [attr.aria-expanded]="!isCollapsed"
      class="btn d-flex align-items-center justify-content-between w-100"
    >
      <span class="d-flex align-items-center">
        @if (menuItem!.icon) {
          <i class="material-icons me-2">
            {{ menuItem!.icon }}
          </i>
        }
        {{ menuItem!.title }}
      </span>
      <i class="material-icons">
        {{ isCollapsed ? "keyboard_arrow_down" : "chevron_right" }}
      </i>
    </button>
  } @if (menuItem!.children && isCollapsed) {
    <div>
      <div class="col-12 ps-4 pt-3">
        @for (menu of menuItem?.children; track menu.route) {
          <app-menu-item [menuItem]="menu"></app-menu-item>
        }
      </div>
    </div>
  }
</div>
