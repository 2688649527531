import { Menu } from "../models/menu.model";

export const MENU: Array<Menu> = [
  {
    icon: 'dashboard',
    route: '/dashboard',
    title: 'Dashboard',
  },
  {
    'icon': 'description',
    'title': 'Certificados',
    route: 'certificates',
  },
  {
    icon: 'article',
    title: 'Estandares',
    route: 'standards',
    rolesCanAccess: ['admin', 'sudo'],
  },
  
];