import { Component, Input } from '@angular/core';
import { Menu } from '../../models/menu.model';
import { SlideMenuService } from '../../services/slide-menu.service';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrl: './menu-item.component.scss'
})
export class MenuItemComponent {

  @Input() menuItem: Menu | undefined;

  public isCollapsed = false;

  constructor(
    public slideMenuService: SlideMenuService
  ) {}

}
